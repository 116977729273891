* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
  background-color: #111;
}

html,
body {
  overflow: hidden;
}

html,
body,
#__next,
main {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.header {
  max-width: 500px;
  padding-inline: 2rem;
}

.header>img {
  display: block;
  margin: auto;
  max-width: 100%;
}

.lk-participant-tile .lk-participant-placeholder {
  svg {
    padding: 20px !important;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.MuiAvatar-circular {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.header>h2 {
  font-family: 'TWK Everett', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 144%;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  background-color: var(--lk-bg);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

footer a,
h2 a {
  color: #ff6352;
  text-decoration-color: #a33529;
  text-underline-offset: 0.125em;
}

footer a:hover,
h2 a {
  text-decoration-color: #ff6352;
}

h2 a {
  text-decoration: none;
}

.lk-video-conference.side-bar-open {

  &.screen-750,
  &.screen-500 {
    .lk-video-conference-inner {
      width: 100%;
    }

    .lk-chat {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      bottom: var(--lk-control-bar-height);
    }
  }

  &.screen-1000 {
    .lk-video-conference-inner {
      width: 50%;
    }
  }

  &.screen-1250 {
    .lk-video-conference-inner {
      width: 60%;
    }
  }
}

.settings-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .lk-chat-header {
    height: 50px;

    .lk-close-button {
      right: -5px;
      padding: 5px 8px 2px;
      border-radius: 10px;
      cursor: pointer;
    }
  }


  .tab-content {
    background-color: var(--lk-bg3);
    overflow: auto;
    flex: 1;
    padding: 15px 15px 0 15px;
  }
}


.lk-participant-media-video {
  background-color: transparent;
}

.lk-participant-tile {
  .lk-participant-media-video {
    background-color: var(--lk-bg2);
  }
}

body .lk-participant-media-video[data-lk-orientation=landscape] {
  object-fit: contain
}

.template-view {
  .lk-participant-media-video[data-lk-orientation=landscape] {
    object-fit: contain !important;
  }

  .lk-carousel .lk-participant-media-video[data-lk-orientation=landscape] {
    object-fit: cover !important;
  }

  .video-cover {
    .lk-participant-media-video[data-lk-orientation=landscape] {
      object-fit: cover !important;
    }

    .lk-participant-media-video[data-lk-source=screen_share] {
      object-fit: contain !important;
    }

    img {
      object-fit: cover !important;
    }
  }
}



.lk-participant-placeholder {
  img {
    width: 100%;
    height: 100%;
    object-fit: 'contain';
  }
}

.roomContainer {
  height: 100vh;
  position: relative;
}

.template-view {
  height: 100vh;
}

.lk-prejoin {
  display: flex;
  width: min(100%, 95%) !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100%;

  .lk-video-container {
    display: flex;
    justify-content: center;

    .lk-camera-off-note {
      position: relative !important;
    }
  }

  &.screen-500 {
    flex-direction: column !important;

    .lk-video-container {
      width: 100% !important;

      >video {
        width: 100% !important;
      }
    }
  }

  .lk-video-container {
    width: 100% !important;
    max-height: 270px !important;
    max-width: 430px;

    >video {
      width: auto !important;
      object-fit: contain;
    }
  }
}

.lk-prejoin .lk-join-button {
  --lk-control-fg: rgba(0, 0, 0, 0.87) !important;
  --lk-control-bg: #0cc2aa !important;
  --lk-control-hover-bg: rgb(8, 135, 118) !important;
  background-color: var(--lk-control-bg);
  text-transform: uppercase;
}

.lk-prejoin .lk-login-button {
  --lk-control-fg: white !important;
  --lk-control-bg: rgb(93, 94, 106) !important;
  --lk-control-hover-bg: rgb(65, 65, 74) !important;
  background-color: var(--lk-control-bg);
  text-transform: uppercase;
}

body .lk-device-menu {
  margin-top: 15px;
}

body .lk-media-device-select {
  li {
    margin-bottom: -2px;

    >.lk-button {
      padding: 0px 5px;
      font-size: 0.8em;
    }
  }

  li:not(:last-child) {
    margin-bottom: 0;
  }
}

body .lk-carousel[data-lk-orientation=vertical] {
  overflow-y: hidden;
}

.very-good {
  color: #4caf50 !important;
  fill: #4caf50 !important;
}

.good {
  color: #8bc34a !important;
  fill: #8bc34a !important;
}

.average {
  color: #ff7f00 !important;
  fill: #ff7f00 !important;
}

.bad {
  color: #f44336 !important;
  fill: #f44336 !important;
}


.very-good-bg {
  background: #4caf50 !important;
}

.good-bg {
  background: #8bc34a !important;
}

.average-bg {
  background: #ff7f00 !important;
}

.bad-bg {
  background: #f44336 !important;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed !important;

  * {
    cursor: not-allowed !important;
  }
}

.icon-on {
  color: #8bc34a;
}

.icon-off {
  color: #f44336;
}